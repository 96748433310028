import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '../../../services/appsetting.service';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    address: string;
    telephone: string;
    funeralFundTel: string;
    fax: string;
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
    youtube: string;
    funeralFundMail: string;
    email: string;
    urgentFuneralMail:string;
    urgentFuneralTel:string;

    constructor(private appSettingService: AppSettingService) { }

    ngOnInit(): void {
        this.getAppSettings()
    }

    getAppSettings() {
        this.appSettingService.getAppSetting().subscribe((data: any) => {
            this.address = data.find(item => item.key === "adres")?.value;
            this.telephone = data.find(item => item.key === "telefon")?.value;
            this.funeralFundTel = data.find(item => item.key === "cenazetelefon")?.value;
            this.fax = data.find(item => item.key === "fax")?.value;
            this.funeralFundMail = data.find(item => item.key === "cenazemail")?.value;
            this.email = data.find(item => item.key === "email")?.value;
            this.urgentFuneralMail = data.find(item => item.key === "cenazeacilmail")?.value;
            this.urgentFuneralTel = data.find(item => item.key === "cenazeaciltelefon")?.value;
            //   this.facebook = data.find(item => item.key === "facebook")?.value;
            //   this.twitter = data.find(item => item.key === "twitter")?.value;
            //   this.instagram = data.find(item => item.key === "instagram")?.value;
            //   this.linkedin = data.find(item => item.key === "linkedin")?.value;
            //   this.youtube = data.find(item => item.key === "youtube")?.value;


        })
    }

}